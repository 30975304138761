.footer {
    background-color: var(--esg-azul-sitec); 
    color: #fff;
    padding: 20px;
    text-align: center;
    
    height: 300px;
  }
  @media (max-width: 480px) {
    .footer {
      background-color: var(--esg-azul-sitec);
      color: #fff;
      text-align: center;
      
      height: 300px;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #c7c5c5;
    
  }
  .coop {
    color: #c7c5c5;
  font-family: 'Bai Jamjuree', sans-serif;

  }
  h1, h2, h3, h4, h5, h6, p {
    font-family: 'Bai Jamjuree', sans-serif;
  
  }
  .custom-footer {
    padding-top: 50px;
    margin: 0 auto;
    height: 200px;
    text-align: center; /* Centraliza o título */
}
.pFaleConosco {
  margin-left: 9%;
}

.iconsRedesContainer {
  display: inline-flex; 
    justify-content: center; /* Alinha os ícones no centro */
    margin-top: 10px; /* Espaçamento entre o título e os ícones */
}
.iconsRedes {
  margin: 0; /* Remove as margens dos elementos <p> */
}

.iconsRedes img {
    width: 30px; /* Tamanho dos ícones (ajuste conforme necessário) */
    height: auto;
    margin: 0; 
}
  @media (max-width: 480px) {
    .custom-footer {
      margin: auto;
      height: 1px;
    }
  }

  .div1-img-whats {
    background-image: url('./images/whatsapp.png') ; /* Caminho para imagem de fundo da Div 1 */
    border-radius: 15px;
    
  }

  .iconsRedes {
    float: left;
    padding-left: 50px;
  }
  @media (max-width: 480px) {
    .iconsRedes {
      float: left;
    
    }
    .icones {
      padding-top: 20px;
      height: 35px;
      width: 300px;
      margin: auto;
  }
  
  }

  @media (max-width: 768px) {
    .iconsRedes {
      float: left;
    
    }
    .icones {
      padding-top: 20px;
      height: 35px;
      width: 300px;
      margin: auto;
  }
  
  }


  .whatsApp {
    width: 32px;
    height: auto;
  }
  .instagram {
    width: 32px;
    height: auto;
  }
  .email {
    width: 32px;
    height: auto;
  }