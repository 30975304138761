:root {
  --fonte-cinza: #333;
  --azul-forte: #163c6e;
  --branco: white;
  --cx-cinza: #dfdfdf;
  --bg-azul: #225dbc;
  --bg-cinza: #dfdfdf;
  --bg-branco-2: white;
  --black: black;
  --fonte-cinza-claro: #7c7c7c;
  --esg-siemens: #099a9a;
  --esg-verde: #368d35;
  --esg-azul-sitec: #00071e;
  --amarelo:#ad952a;
}

header {
  background-color: var(--branco);
  width: 100%;
  height: 10%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  font-family: 'Bai Jamjuree', sans-serif;

}
@media (max-width: 480px) {
  .header-custom {
    
    background-color: var(--branco);
    width: 100%;
    height: 6%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}
.menu-item {
  color: var(--azul-forte);
  text-decoration: none;
  font-weight: bold; /* Negrito */
  font-family: 'Bai Jamjuree', sans-serif;

}

@media (max-width: 736px) {
  .menu-item {
    color: var(--azul-forte);
  text-decoration: none;
  font-weight: bold; /* Negrito */
  font-family: 'Bai Jamjuree', sans-serif;
  
  
  }
}

.menu {
  float: right;
  margin-top: 50px;
  font-family: 'Bai Jamjuree', sans-serif;
  width: 100%;
  height: 40px;

}
@media (max-width: 480px) {
  .menu {
    float: right;
    margin-right: 15%;
    margin-top: 5%;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}
@media (max-width: 460px) {
  .menu {
    float: right;
    margin-right: 15%;
  font-family: 'Bai Jamjuree', sans-serif;
  margin-top: 4.5%;
  }
}
@media (max-width: 420px) {
  .menu {
    float: right;
    margin-right: 13%;
    margin-top: 4%;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}
@media (max-width: 402px) {
  .menu {
    float: right;
    margin-right: 10%;
    margin-top: 3.5%;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}

.menu a {
  display: inline-block;
  margin: 0 10px; /* Adiciona margem entre os parágrafos */
  font-size: 30px;
  font-family: 'Bai Jamjuree', sans-serif;

}

@media (max-width: 816px) {
  .menu a {
    display: inline-block;
    margin: 0 10px; /* Adiciona margem entre os parágrafos */
    font-size: 20px;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}

@media (max-width: 480px) {
  .menu a {
    display: inline-block;
    margin: 0 10px; /* Adiciona margem entre os parágrafos */
    font-size: 9px;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}

body {
  background-image: url('images/fundo2.jpg') ;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0;
  margin-top: 10px;
  font-family: 'Bai Jamjuree', sans-serif;
}


@media (max-width: 480px) {
  body {
    background-image: url('images/fundo2.jpg') ;

    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0;
    margin-top: 10px;

    font-family:
    'Titillium Web', sans-serif;
  }
}


.divLogo {
  float: left;
}

.divMenus {
  float: right;
  margin-right: 15px;
  border: 1px solid red;

  
}

.logo {
  float: left;
  margin-top: 1%;
  margin-left: 15%;
  width: 50%;
  
}
@media (max-width: 480px) {
  .logo {
    float: left;
    margin-top: 5%;
    margin-left: 15%;
    width: 6%;
  }
}
@media (max-width: 460px) {
  .logo {
    float: left;
    margin-top: 4.9%;
    margin-left: 15%;
    width: 6%;
  }
}
@media (max-width: 420px) {
  .logo {
    float: left;
    margin-top: 4.7%;
    margin-left: 13%;
    width: 6%;
  }
}
@media (max-width: 402px) {
  .logo {
    float: left;
    margin-top: 4.5%;
    margin-left: 10%;
    width: 6%;
  }
}



.fundo {
  height: 1100px;
}

@media (max-width: 768px) {
  .fundo {
    height: 900px;
  }
}

.services {
  background-color: var(--esg-azul-sitec);
  width: 100%;
  height: 800px; 
  position: relative;
  
}
.backAllPages {
  background-color: var(--bg-cinza);
  width: 100%;
  height: 800px; 
  position: relative;
}

@media (max-width: 480px) {
  .services {
    background-color: var(--esg-azul-sitec);
    width: 100%;
    height: 400px; 
    position: relative;
  }
}

.products {
  background-color: var(--bg-cinza);
  width: 100%;
  height: 1400px;
  position: relative;
}
@media (max-width: 480px) {
  .products {
    background-color: var(--bg-cinza);
    width: 100%;
    height: 1300px;
    position: relative;
  }
}
.div-products {
  background-color: var(--bg-cinza);
  color: var(--esg-azul-sitec);
  margin: auto;
  text-align: center;
}

.text-products {
  padding-top: 80px;
  font-size: 60px;
}
.text-products2 {
  padding-top: 60px;
  font-size: 25px;
}
@media (max-width: 480px) {
  .text-products2 {
    padding-top: 20px;
    font-size: 20px;
  }
}

.projects {
  background-color: var(--bg-cinza);
  width: 100%;
  height: 1400px;
  position: relative;
  border-bottom: 1px solid var(--esg-azul-sitec)
}
@media (max-width: 480px) {
  .projects {
    background-color: var(--bg-cinza);
    width: 100%;
    height: 1300px;
    position: relative;
    border-bottom: 1px solid var(--esg-azul-sitec)
  }
}
.div-projects {
  background-color: var(--bg-cinza);
  color: var(--esg-azul-sitec);
  margin: auto;
  text-align: center;
}

.text-projects {
  padding-top: 80px;
  font-size: 60px;
}
.text-projects2 {
  padding-top: 60px;
  font-size: 25px;
  font-family: 'Bai Jamjuree', sans-serif;

}
@media (max-width: 480px) {
  .text-projects2 {
    padding-top: 20px;
    font-size: 20px;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}
.texto {
  z-index: 2;
  margin-top: 200px;
  font-size: 6rem;
    line-height: 1.1;
    font-weight: 400;
    color: var(--bg-cinza);
  font-family: 'Bai Jamjuree', sans-serif;
    
    text-align: center;
      opacity: 0;
      animation: fade-in 3s ease forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
h1, h2, h3, h4, h5, h6, p {
  font-family: 'Bai Jamjuree', sans-serif;

}
@media (max-width: 950px) {
  .texto {
    z-index: 2;
    
    margin-top: 120px;
    font-size: 50px;
      line-height: 1.1;
      font-weight: 400;
      color: var(--bg-cinza);
  font-family: 'Bai Jamjuree', sans-serif;

      text-align: center;
        opacity: 0;
        animation: fade-in 3s ease forwards;
  }
}
@media (max-width: 480px) {
  .texto {
    z-index: 2;
    
    margin-top: 80px;
    font-size: 25px;
      line-height: 1.1;
      font-weight: 400;
      color: var(--bg-cinza);
  font-family: 'Bai Jamjuree', sans-serif;

      text-align: center;
        opacity: 0;
        animation: fade-in 3s ease forwards;
  }
}
.texto2 {
  font-size: 1.4rem;
  margin: auto;

    text-align: center;
    color: var(--bg-cinza);
  font-family: 'Bai Jamjuree', sans-serif;

    line-height: 1.5;
    margin-bottom: 80px;
    opacity: 0;
      animation: fadeIn 5s ease forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 480px) {
  .texto2 {
    font-size: 15px;
    width: 80%;
      margin: auto;
      text-align: center;
      color: var(--bg-cinza);
  font-family: 'Bai Jamjuree', sans-serif;

      line-height: 1.5;
      margin-bottom: 80px;
      opacity: 0;
        animation: fadeIn 5s ease forwards;
  }
}
.custom-title2 {
  margin-top: 80%;
  opacity: 0;
  transform: scale(0.8);
}
@media (max-width: 480px) {
  .custom-title2 {
    margin-top: 60%;
    opacity: 0;
    transform: scale(0.8);
  }
}

.custom-title {
  opacity: 0;
  transform: scale(0.8);
  width: 350px;
  
 
}

@media (max-width: 480px) {
  .custom-title {
    opacity: 0;
    transform: scale(0.8);
    font-size: 13px;
  }
}
.custom-title-projects {
  opacity: 0;
  transform: scale(0.8);
}
.custom-title-products {
  opacity: 0;
  transform: scale(0.8);
}
.fadeInScale {
  opacity: 1;
  animation: fadeInScale 1s ease forwards;
}
@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fadeInSlide {
  opacity: 1;
  animation: slideFromLeft 1s ease forwards;
}
@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInSlide2 {
  opacity: 1;
  animation: slideFromLeft2 1s ease forwards;
}
@keyframes slideFromLeft2 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
.custom-container {
  margin: auto;
  border: #ad952a solid;
  background-color: #ad952a;
  height: 30%; /* Altura desejada */
  padding-top: 20px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .custom-container {
    margin: auto;
    border: #ad952a solid;
    background-color: #ad952a;
    height: 40%; /* Altura desejada */
    border-radius: 15px;
  }
}

@media (max-width: 480px) {
  .custom-container {
    margin: auto;
    border: #ad952a solid;
    background-color: #ad952a;
    height: 50%; /* Altura desejada */
    border-radius: 15px;
  }
}

.custom-container-two {
  height: 10%; /* Altura desejada */
  padding-top: 200px;
  border-radius: 15px;
  
}
@media (max-width: 480px) {
  .custom-container-twoo {
    height: 10%; /* Altura desejada */
    border-radius: 15px;
  }
}

.custom-container-three {
  height: 30%; /* Altura desejada */
  padding-top: 100px;
  border-radius: 15px;
}
@media (max-width: 480px) {
  .custom-container-three {
    height: 30%; /* Altura desejada */
    padding-top: 25px;
    border-radius: 15px;
  }
}

.custom-row-miniaturas {
  width: 320px;
  margin: auto;
  height: 213px;
  background-color: #ad952a;
  
}

@media (max-width: 480px) {
  .custom-row-miniaturas {
    width: 320px;
    margin: auto;
    height: 213px;
    background-color: #ad952a;
    
  }
}
.custom-row-miniaturas-txt {
  width: 320px;
  height: 100px;
  margin: auto;
  background-color: #ad952a;
  font-size: 28px;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;


}

@media (max-width: 480px) {
  .custom-row-miniaturas-txt {
    width: 200px;
    height: 50px;
    margin: auto;
    background-color: #ad952a;
    font-size: 20px;
    text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;

  }
}

.custom-row-carousel {
  height: 325px;
}
@media (max-width: 480px) {
  .custom-row-carousel {
    height: 250px;
  }
}
.custom-row-frame-two-AllPages {
  width: 150px;
  margin: auto;
  height: 213px;
  background-color: var(--bg-cinza);
  color:var(--esg-azul-sitec);
  text-align: center
  
}
@media (max-width: 480px) {
  .custom-row-frame-two-AllPages {
    padding-top: 15px;
    width: 300px;
    margin: auto;
    height: 160px;
    background-color: var(--bg-cinza);
    color:var(--esg-azul-sitec);
    text-align: center;

    
  }
}

.custom-row-frame-two {
  padding-top: 20px;
  width: 350px;
  margin: auto;
  height: 213px;
  background-color: var(--esg-azul-sitec);
  color:white;
  text-align: center;
  
}
@media (max-width: 480px) {
  .custom-row-frame-two {
    padding-top: 15px;
    width: 300px;
    margin: auto;
    height: 160px;
    background-color: var(--esg-azul-sitec);
    color:white;
    text-align: center;

    
  }
}
.custom-div-frame-two2 {
  margin: auto;
  width: 500px;
  padding-top: 40px;
  height: 456px; /* Altura desejada */
  background-size: cover; /* Ajusta o tamanho da imagem para cobrir a div */
  background-position: center; /* Centraliza a imagem */
  border-radius: 15px;
}

@media (max-width: 480px) {
  .custom-div-frame-two2 {
    margin: auto;
    width: 300px;
    height: 200px; /* Altura desejada */
    background-size: cover; /* Ajusta o tamanho da imagem para cobrir a div */
    background-position: center; /* Centraliza a imagem */
    border-radius: 15px;
  }
}

.custom-row {
  margin-top: 15px;
}

.custom-row-frame-three {
  width: 150px;
  margin: auto;
  height: 400px;
  background-color: var(--bg-cinza);
  color:white;
  text-align: center
}
@media (max-width: 768px) {
  .custom-row-frame-three {
    width: 350px;
    margin: auto;
    height: 410px;
    background-color: var(--bg-cinza);
    color:white;
    text-align: center
  }
}
@media (max-width: 480px) {
  .custom-row-frame-three {
    width: 300px;
    margin: auto;
    height: 300px;
    background-color: var(--bg-cinza);
    color:white;
    text-align: center
  }
}

.custom-row-frame-three2 {
  width: 150px;
  margin: auto;
  height: 400px;
  background-color: var(--bg-cinza);
  color:white;
  text-align: center
}

.custom-div-frame-two {
  height: 456px; /* Altura desejada */
  margin-right: 100px;
  background-size: cover; /* Ajusta o tamanho da imagem para cobrir a div */
  background-position: center; /* Centraliza a imagem */
}

.div1-frame-two {
  background-image: url('./images/direita.png'); /* Caminho para imagem de fundo da Div 1 */
  border-radius: 15px;
}

.div1-frame-conteudo {
  background-image: url('./images/conteudo.png'); /* Caminho para imagem de fundo da Div 1 */
  border-radius: 15px;
}



.custom-div {
  height: 200px; /* Altura desejada */
  background-size: cover; /* Ajusta o tamanho da imagem para cobrir a div */
  background-position: center; /* Centraliza a imagem */
}

@media (max-width: 480px) {
  .custom-div {
    height: 140px; /* Altura desejada */
    background-size: cover; /* Ajusta o tamanho da imagem para cobrir a div */
    background-position: center; /* Centraliza a imagem */
    width: 200px;
  }
}

.div1-img-frame1 {
  background-image: url('./images/76251.png'); /* Caminho para imagem de fundo da Div 1 */
  border-radius: 15px;
}

.div2-img-frame1 {
  background-image: url('./images/Automação.png'); /* Caminho para imagem de fundo da Div 2 */
  border-radius: 15px;
}

.div3-img-frame1 {
  background-image: url('./images/76251.png'); /* Caminho para imagem de fundo da Div 3 */
  border-radius: 15px;
}


.div1-img-frame3 {
  background-image: url('./images/244103.png'); /* Caminho para imagem de fundo da Div 1 */
  border-radius: 15px;
}

.div2-img-frame3 {
  background-image: url('./images/244103.png'); /* Caminho para imagem de fundo da Div 2 */
  border-radius: 15px;
}

.div3-img-frame3 {
  background-image: url('./images/244103.png'); /* Caminho para imagem de fundo da Div 3 */
  border-radius: 15px;
}
.div4-img-frame3 {
  background-image: url('./images/244104.png'); /* Caminho para imagem de fundo da Div 1 */
  border-radius: 15px;
}

.div5-img-frame3 {
  background-image: url('./images/244104.png'); /* Caminho para imagem de fundo da Div 2 */
  border-radius: 15px;
}

.div6-img-frame3 {
  background-image: url('./images/244104.png'); /* Caminho para imagem de fundo da Div 3 */
  border-radius: 15px;
}

